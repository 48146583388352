<template>
  <div class="products-wrap fade-in-top">
    <h2>Proizvodi: </h2>
    <div class="products">
        <ProductCard v-for="product in products" :key="product.id" :product="product"/>
    </div>  
  </div>  
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";

export default {
  name: "Products",
  components: {
    ProductCard
  },
  data() {
    return{
    }
  },
  computed:{
    products () {
      return this.$store.state.products;
    }
  }
}

</script>

<style lang="scss" scoped>
  .products-wrap{
    margin: 105px auto 0;
    width: 75%;
    padding: 20px 10px;
    border-top: 1px solid #7395ae96;
    border-bottom: 1px solid #7395ae96;
    h2{
      margin: 5px;
    }
    .products{
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 800px) {
    .products-wrap {
      width: 90%;
    }
  }

</style>