<template>
  <div class="product-card fade-in-top" @click="fetchSingleProductWithDetails(product.id)">
      <div class="product-cover">
        <img :src="product.imgs[0].imgSrc" :alt="product.imgs[0].imgSrc">
      </div>
      <div class="product-name">
          <h3> {{product.name}} </h3>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        product: Object
    },
    methods: {
        fetchSingleProductWithDetails (id){
            let productId = id;
            this.$router.push({ name: 'ProductCardDetails', params: { id: productId } });
        }
    }
}
</script>

<style lang="scss" scoped>
    .product-card{
        margin: 15px;
        width: 320px;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 1px 1px 3px rgba(34, 34, 34, 0.53);
        transition: 0.3s;
        border-radius: 7px;
        &:hover{
            box-shadow: 5px 5px 5px rgba(22, 22, 22, 0.53);
            transform: scale(1.04);
            cursor: pointer;
        }
        .product-cover {
            height: 340px;
            border-radius: 7px;
        }
        .product-name{
            height: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 3px;
            // background: #7395ae url("../assets/backgroundblue.png") ;
            // background-size: 100% 100%;
            background: #7395ae;
            color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
</style>